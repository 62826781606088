import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    IBM: {
      jobTitle: " Backend Software Developer Intern @",
      duration: "MAY 2024 - MAY 2025",
      desc: [
        "API Security + Template Service"
      ]
    },
    NASA: {
      jobTitle: "Wireless Networks Engineering Intern @",
      duration: "JAN 2024 - MAY 2024",
      desc: [
        "Enhanced network performance analysis scripts with selenium python-based web scraper for Sierra Wireless RV55 LTE hotspot integration.",
        "Developed a Python selenium web scraper script to log into RV55 hotspot, collecting key RAN signal strength metrics (RSSI, RSRP, SINR). Utilized Python within a Linux environment, demonstrating proficiency in both.",
        "Build a Raspberry Pi GPS system (Header and T_PULSE cable soldering, HAT installation).",
        "Crafted an exponential backoff timer in Node.Js/Javascript for WebRTC astronaut lunar communication software."
      ]
    },
    Logitech: {
      jobTitle: "Audio Engineering Intern @",
      duration: "JUN 2023 - SEPT 2023",
      desc: [
        "Write software unit tests in C/C++ to enable the audio team to verify software production code.",
        "Set up Docker environments for different architecture targets.",
        "Created a software applications in C/C++ to enable the audio team to evaluate audio algorithm libraries offline."
      ]
    },
    "Northrop Grumman": {
      jobTitle: "College Technical Intern: SpriNGboard Enrichment Program @",
      duration: "JAN 2023 - MAY 2023",
      desc: [
        "The training program included weekly meetings with a software engineering mentor, software and computer science exercises and weekly deliverables pushed to GitHub.",
        "Topics covered included bits and bytes, classes, objects, and types, code organization, refactoring and test-driven development using Catch-2 and Pytest, threads, processes, concurrency, and containers."
      ]
    },
    iCode: {
      jobTitle: "Technical Instructor @",
      duration: "OCT 2022 - JAN 2023",
      desc: [
        "Instructed various computer science-related courses in Web Development, Computer Hardware, & Raspberry Pi Programming.",
        "Mentored students on how to build their websites, games, & applications.",
        "Inspired K 12 students to use programming to solve real-world problems."
      ]
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;